import { FC, ReactNode } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Typography from '@/ui/components/typography';

const validListStyles = ['ordered', 'unordered'];

const ListRenderer: FC<{ data: string | { items: string[], style: string } }> = ({
    data,
}) => {
    let content: ReactNode[] = [];
    let listType = 'unordered';

    if (typeof data === 'string') {
        content.push(data);
    } else if (typeof data === 'object') {
        if (data.items && Array.isArray(data.items)) {
            content = data.items.map((item) => (
                <Typography
                    key={item}
                    as="li"
                    variant="normal"
                >{ReactHtmlParser(item)}
                </Typography>
            ));
        }
        if (data.style && validListStyles.includes(data.style)) {
            listType = data.style;
        }
    }

    if (content.length <= 0) {
        return null;
    }
    if (listType === 'ordered') {
        return <ol>{content}</ol>;
    }

    return <ul>{content}</ul>;
};

export default ListRenderer;
